#hls-player {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
    margin-left: 100px;
    margin-right: 50px;

    justify-content: center;
    text-align: center;
}

.skip-stream-button {
    margin-top: 20px;
    margin-bottom: 20px;
}
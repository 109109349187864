#channel-dropdown {
    display: flex;
    justify-content: center;
    width: 100%;
}

#channel-dropdown .dropdown-menu {
    width: 100%;
    height: 200px;
    overflow-y: scroll;
}

#channel-dropdown .dropdown {
    width: 60%;
}

#channel-dropdown .btn-primary {
    width: 100%;
}
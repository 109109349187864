#modal {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 3;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0.5);
}

#modal-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    background-color: white;
}

#modal-content {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
}

#modal-title {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}

#modal-login-button {
    margin-bottom: 30px;
}

#modal-error-message {
    color: red;
    text-align: center;
}
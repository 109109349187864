#metadata-visualizer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 100px;
}

#metadata-visualizer-favorite-button {
    margin-left: '.5rem';
}

.input-group-text {
    min-width: 75px;
}
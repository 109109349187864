#demo-title {
    margin-top: 30px;
    margin-bottom: 75px;
    text-align: center;
}

#demo-mainframe {
    display: flex;
    flex-direction: row;
    width: 100%;
}

#demo-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-right: 100px;
    margin-left: 100px;
    margin-top: 50px;
}

.empty-div {
    width: 100%;
}